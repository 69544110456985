import { useState, useEffect } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';

import { supabase } from '../supabaseClient';
import LoadingIcon from './LoadingIcon';

const Form = ({ skus, loading, title, longTerm = false }) => {
  const user = supabase.auth.user();
  const [submitting, setSubmitting] = useState(false);
  const [newSku, setNewSku] = useState('');
  const [allSkus, setAllSkus] = useState([]);

  useEffect(() => {
    setAllSkus(skus);
  }, [skus]);

  const notifySuccess = (message = 'New setting has been saved') =>
    toast.success(message);

  const addSku = async (event) => {
    event.preventDefault();
    const form = event.target;

    try {
      setSubmitting(true);
      const skus = newSku.split(',').map((x) => ({
        is_long_term: longTerm,
        user_id: user.id,
        sku: x.trim(),
      }));
      // console.log(skus);

      const { data, error } = await supabase.from('skus').upsert(skus, {
        returing: 'minimal',
        ignoreDuplicates: true,
      });

      if (data) {
        data.forEach(({ sku }) => {
          setAllSkus((oldArray) => [...oldArray, { sku }]);
        });
        notifySuccess('SKU has been added to filter');
      }

      if (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      form.reset();
      setSubmitting(false);
    }
  };

  const removeAllSkus = async (e) => {
    e.preventDefault();

    if (
      window.confirm('This will remove ALL SKUs from filter. Are you sure?') ===
      true
    ) {
      try {
        setSubmitting(true);

        const { data, error } = await supabase
          .from('skus')
          .delete()
          .match({ user_id: user.id, is_long_term: longTerm });

        if (data) {
          notifySuccess('All SKUs removed from filter');
          setAllSkus([]);
        }

        if (error) {
          console.error(error.message);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const removeSku = async (key) => {
    try {
      const { data, error } = await supabase
        .from('skus')
        .delete()
        .match({ user_id: user.id, sku: key });

      if (data) {
        setAllSkus((prev) => prev.filter(({ sku }, idx) => sku !== key));
        notifySuccess('SKU has been removed from filter');
      }

      if (error) {
        console.error(error.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="h-screen w-full container">
      <div className="grid">
        <h4 className="justify-self-center text-slate-500">{title}</h4>
        <a
          className="mr-3 text-sm justify-self-end hover:underline"
          href="#"
          onClick={removeAllSkus}
        >
          <span className="text-red-500">Clear List</span>
        </a>
      </div>
      <div className="h-2/3 border border-slate-300 overflow-scroll mr-3">
        {loading ? (
          <div className="flex justify-center h-full text-center">
            <h6 className="self-center text-slate-400">Checking for SKUs...</h6>
          </div>
        ) : allSkus.length > 0 ? (
          allSkus.map(({ sku }, index) => (
            <div
              key={index}
              className="flex m-2 border border-slate-300 rounded-md h-10"
            >
              <span className="w-full ml-7 self-center">{sku}</span>
              <XIcon
                className="mr-7 self-center h-6 hover:h-8 w-6 hover:w-8 cursor-pointer transition ease-in-out"
                onClick={() => removeSku(sku)}
              />
            </div>
          ))
        ) : (
          <div className="flex justify-center h-full w-full border text-center">
            <h6 className="text-sm self-center text-slate-500">
              No SKUs found. Please add SKUs below to exclude from update.
            </h6>
          </div>
        )}
      </div>

      <form className="mr-3" onSubmit={addSku}>
        <div className="mt-2 flex justify-between">
          <div className="w-full">
            <input
              required
              type="text"
              className="border-slate-300 outline-none"
              placeholder="SKU-123,SKU-456,..."
              onChange={(e) => {
                setNewSku(e.target.value);
              }}
            />
          </div>

          <div>
            <button
              className="flex ml-0.5 bg-sky-300 border-slate-300"
              disabled={submitting}
              type="submit"
            >
              {submitting && <LoadingIcon />}
              <span className="w-full">
                {submitting ? 'Saving...' : 'Save'}
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Form;
