import { useState, useEffect } from 'react';

import { supabase } from './supabaseClient';
import Form from './components/Form';

const Account = () => {
  const user = supabase.auth.user();
  const [loading, setLoading] = useState(true);
  const [skus, setSkus] = useState([]);

  const getSkus = async () => {
    try {
      setLoading(true);
      const { data, error, status } = await supabase
        .from('skus')
        .select('sku, is_long_term')
        .eq('user_id', user.id);

      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        // console.log(data, 'initial data');
        setSkus(data);
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSkus();
  }, []);

  return (
    <div className="h-screen">
      <h4 className="text-center">Export Stash x RWS SKU Filter</h4>
      <h6 className="text-center text-sm mb-4">
        All SKUs in the lists below will <strong>not</strong> be included in the
        pricing update.
      </h6>

      <div className="flex justify-around">
        <Form
          title="Short-term Filter List"
          skus={skus.filter((s) => !s.is_long_term)}
          loading={loading}
        />
        <Form
          longTerm
          title="Long-term Filter List"
          skus={skus.filter((s) => s.is_long_term)}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Account;
