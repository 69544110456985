import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { Fragment, useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { supabase } from './supabaseClient';
import Auth from './Auth';
import Account from './Account';

const App = () => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    setSession(supabase.auth.session());

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  return (
    <div className="container" style={{ padding: '20px 0 100px 0' }}>
      {!session ? (
        <Auth />
      ) : (
        <Fragment>
          <Account key={session.user.id} session={session} />
          <ToastContainer
            hideProgressBar={true}
            pauseOnFocusLoss={false}
            autoClose={400}
          />
        </Fragment>
      )}
    </div>
  );
};

export default App;
